<template>
  <div class="section">
      <div class="section-all" >
          <div class="footermap"></div>
          <div class="footer-company">
              <div class="footer-company__left" :class="footer">
                  <h3 class="size28">合肥和行科技有限公司</h3>
                  <div class="footer-company__left-p size16">
                      <p>地址：合肥市经开区紫蓬路1325号</p>
                      <p>电话：400-160-8899</p>
                      <p>邮箱：kf@jachx.com</p>
                  </div>
              </div>
              <div class="footer-company__right" :class="footerRight">
                  <h3 class="size28">关注</h3>
                  <div class="footer-company__right-bottom">
                      <div class="footer-company__right-wechat">
                          <div class="footer-company__right-thide">
                              <img src="../../../static/images/home/gzh.jpg" />
                          </div>
                      </div>
                      <div class="footer-company__right-btn sj">
                          <span>司机端APP</span>
                          <div class="footer-company__right-thide sjm">
                              <img src="../../../static/images/driver.png" />
                          </div>
                      </div>
                      <div class="footer-company__right-btn ck">
                          <span>乘客端APP</span>
                          <div class="footer-company__right-thide ckm ">
                              <img src="../../../static/images/passengers.png" />
                          </div>
                      </div>
                      <div class="footer-company__right-btn wx">
                          <span>小程序</span>
                          <div class="footer-company__right-thide wxm ">
                              <img src="../../../static/images/home/wx.jpg" />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
@import url('../../../static/css/home.less');
@import url('../../../static/css/media.less');
.section{
    // 父级设置一定高度 子级才会根据百分比 显示高度
    width:100%;
    height:100vh;
    .footermap {
        width:100%;
        height:33%;
        background: url('../../../static/images/home/map-bg.png')no-repeat top center;
        background-size: cover;
    }
}

</style>